import React, { Component } from "react";
import {
  getAccounts as apiGetAccounts,
  getAccount as apiGetAccount,
} from "./api";
import { Button, Popconfirm, Icon } from "antd";

import PriceTable from "./PriceTable";
import OrderTicket from "./OrderTicket/OrderTicket";
import Tables from "./Tables";
import ReactFlagsSelect from "react-flags-select";
import { Radio } from "antd";
import AccountSelect from "./AccountSelect";

//import io from "socket.io-client";

import authMod from "./auth";

class Trading extends Component {
  constructor(props) {
    super(props);

    // console.log(process.env.PUBLIC_URL);

    this.state = {
      priceTableRecord: "",
      priceTableCell: "",
      accounts: [],
      account: null,
      accountRecord: null,
      servertime: "   ",
    };
    var defaultdaynight = process.env.REACT_APP_COLS;

    // console.log(defaultdaynight);

    this.state = { daynight: defaultdaynight };

    this.onMarketClick = this.onMarketClick.bind(this);
  }

  timer() {
    clearInterval(this.intervalId);
    this.refreshData();
    this.intervalId = setInterval(this.timer.bind(this), 5000);
  }
  componentDidMount() {
    this.refreshData();
    this.intervalId = setInterval(this.timer.bind(this), 5000);
    //const socket = io("http://127.0.0.1:3333");
    //socket.on("servertime", data =>
    //  this.setState({ servertime: data.time, ts: data.ts })
    //);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  refreshData() {
    if (!this.state.accounts || this.state.accounts.length === 0) {
      // Get a list of accounts
      apiGetAccounts().then((res) => {
        // res.data is array of strings, transform into array of json

        let accounts = [];
        res.data.map((elem) => accounts.push({ key: elem }));

        accounts = JSON.parse(JSON.stringify(accounts));

        this.setState({ accounts });
      });
    } else {
      if (this.state.account) {
        apiGetAccount(this.state.account).then((res) => {
          this.setState({ accountRecord: res.data });
          // console.log(res.data);
        });
      }
    }

    //refresh selected account
  }

  handleRadioChange = (e) => {
    // console.log(e.target.value, this.state.daynight);

    if (e.target.value && e.target.value !== this.state.daynight) {
      this.setState({ daynight: e.target.value });

      window.location = new URL("/" + e.target.value, window.location.href);
    }
  };

  handleAccountChange = (newValue) => {
    this.setState({ account: newValue, accountRecord: null });
  };

  onMarketClick(priceTableData) {
    this.setState({
      priceTableRecord: priceTableData.priceTableRecord,
      priceTableCell: priceTableData.priceTableCell,
    });
  }

  onContractChange = () => {
    this.setState({
      priceTableRecord: null,
      priceTableCell: null,
    });
  };

  handleLogout = (e) => {
    authMod.logout(this.props.onLogInOut);
    this.props.history.push("/login");
  };

  //this.props.history.push("/login");

  render() {
    return (
      <div>
        <div className="watermark" />
        <div className="trading">
          <div className="logoContainer">
            <img
              src={require("./ccg_branding/Fintech Islamic Transparent.png")}
              alt="CCG Logo"
              className="logo"
            />
          </div>

          <div className="headerRight">
            <div className="logout">
              <Popconfirm
                title="Log out, yes?"
                icon={
                  <Icon type="question-circle-o" style={{ color: "red" }} />
                }
                onConfirm={this.handleLogout}
              >
                <Button type="primary" shape="circle" icon="lock" />
              </Popconfirm>
            </div>
            <div className="languageSelector">
              <ReactFlagsSelect
                selectedSize={18}
                optionsSize={18}
                placeholder="Choose a language..."
                countries={["GB", "FR", "DE", "IT", "ES", "CD", "ID"]}
                defaultCountry="GB"
                customLabels={{
                  GB: " ",
                  FR: " ",
                  DE: " ",
                  IT: " ",
                  ES: " ",
                  CD: " ",
                  ID: " ",
                }}
              />
            </div>
          </div>
          <div className="accountSelect">
            <AccountSelect
              accountsDataSource={this.state.accounts}
              accountDataSource={this.state.account}
              accountRecordDataSource={this.state.accountRecord}
              onChange={this.handleAccountChange}
            />
          </div>
          <div className="daynight">
            <Radio.Group
              buttonStyle="solid"
              onChange={this.handleRadioChange}
              value={this.state.daynight}
              defaultValue={this.state.daynight}
            >
              <Radio.Button value="night">Night</Radio.Button>
              <Radio.Button value="day">Day</Radio.Button>
            </Radio.Group>
            <div className="serverTime">
              <div>{this.state.servertime}</div>
            </div>
          </div>

          <div className="tradingContainer">
            <OrderTicket
              priceTableRecord={this.state.priceTableRecord}
              priceTableCell={this.state.priceTableCell}
              account={this.state.account}
              onContractChange={this.onContractChange}
            />

            <PriceTable onMarketClick={this.onMarketClick} />

            <Tables className="tablesContainer" />
          </div>
        </div>
      </div>
    );
  }
}

export default Trading;
