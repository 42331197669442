import React, { Component } from "react";
import { Select } from "antd";
//import { tradingAccounts } from "../Development/mockData";

class AccountSelect extends Component {
  onChange = newValue => {
    this.props.onChange(newValue);
  };
  render() {
    const { Option } = Select;

    return (
      <div>
        <div>
          <Select
            value={this.props.accountDataSource}
            showSearch
            style={{ width: "100%" }}
            placeholder="Select Account"
            optionFilterProp="children"
            onChange={this.onChange}
            //onFocus={onFocus}
            //onBlur={onBlur}
            //onSearch={onSearch}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.accountsDataSource
              ? this.props.accountsDataSource.map(account => (
                  <Option key={account.key}>{account.key}</Option>
                ))
              : null}
          </Select>
        </div>

        <div className="accountBalancesContainer ant-select-selection ant-select-selection--single">
          <div className="accountBalancesContents">
            <div>Balance</div>
            <div>
              {this.props.accountRecordDataSource
                ? this.props.accountRecordDataSource[0].Balance +
                  " " +
                  this.props.accountRecordDataSource[0].Tradable
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AccountSelect;
