import React, { Component } from "react";
import { Button } from "antd";

import { parse } from "json2csv";

import { Tabs } from "antd";
import {
  getAccounts as apiGetAccounts,
  getMarkets as apiGetMarkets,
  getPrices as apiGetPrices,
  getOrders as apiGetOrders
} from "./api";

import WorkingOrdersTable from "./OrderTables/WorkingOrders";
import FilledOrdersTable from "./OrderTables/FilledOrders";
import RejectedOrdersTable from "./OrderTables/RejectedOrders";
import CompletedOrdersTable from "./OrderTables/CompletedOrders";

//import io from "socket.io-client";

function transformPrices(pricesJson) {
  var dataSource = [];
  for (var symbol in pricesJson) {
    let item = pricesJson[symbol];
    let topBuyQuote = item.B[0];
    let topBuyQuantity = topBuyQuote ? topBuyQuote.split("@")[0] : "";
    let topBuyPrice = topBuyQuote ? topBuyQuote.split("@")[1] : "";
    let topSellQuote = item.S[0];
    let topSellQuantity = topSellQuote ? topSellQuote.split("@")[0] : "";
    let topSellPrice = topSellQuote ? topSellQuote.split("@")[1] : "";

    dataSource.push({
      PKey: symbol,
      BQty: topBuyQuantity,
      BPx: topBuyPrice,

      SPx: topSellPrice,
      SQty: topSellQuantity,
      TTV: item.TTV,
    });
  }
  return JSON.parse(JSON.stringify(dataSource));
}

const { TabPane } = Tabs;

class App extends Component {
  state = {
    allOrders: [],
    fillOrders: [],
    workingOrders: [],
    notWorkingOrders: [],
    // allAccountBalances: [],
    allPrices: [],
  };

  tab = 0;
  tabs = [];

  notWorkingOrderStates = ["REJECTED", "EXPIRED"];
  workingOrderStates = [
    "PENDING_NEW",
    "PENDING_CANCEL",
    "PENDING_REPLACE",
    "NEW",
    "PARTIALLY_FILLED",
    "STOPPED",
    "SUSPENDED",
  ];
  fillOrderStates = ["FILLED"];
  completedOrderStates = ["FILLED", "CANCELLED", "EXPIRED"];

  //allAccounts = "";
  myAccounts = "";
  allMarkets = "";
  myAccountsFilterList = [];

  refreshData() {
    apiGetAccounts().then((res) => {
      //Splice - too many accounts breaks the system - temporary artificial limit
      this.myAccountsFilterList = [];
      for (var loop in res.data) {
        let account = res.data[loop];
        this.myAccountsFilterList.push({ text: account, value: account });
      }
      this.myAccounts = res.data; //.splice(0, 8).join(",");
    });

    
    apiGetOrders().then(res => {
      if (!res.data) {
        console.log(res);
      }
      this.processOrdersDataImage(res.data);
    });
    
    apiGetMarkets().then((res) => {
      this.allMarkets = res.data.join(",");
      apiGetPrices(res.data).then((res) => {
        let allPrices = transformPrices(res.data);
        this.setState({ allPrices });
      });
    });

    // End Refresh
  }

  processOrdersDataImage(allOrders) {
    //const allOrders = res.data;
    const fillOrders = allOrders.filter((order) =>
      this.fillOrderStates.includes(order.OrdStatus)
    );
    const workingOrders = allOrders.filter((order) =>
      this.workingOrderStates.includes(order.OrdStatus)
    );
    const notWorkingOrders = allOrders.filter((order) =>
      this.notWorkingOrderStates.includes(order.OrdStatus)
    );

    this.setState({
      allOrders,
      fillOrders,
      workingOrders,
      notWorkingOrders,
    });

    this.tabs = [
      { filename: "working_orders.csv", data: workingOrders },
      { filename: "fills.csv", data: fillOrders },
      {
        filename: "rejected_orders.csv",
        data: notWorkingOrders,
      },
      {
        filename: "completed_orders.csv",
        data: fillOrders,
      },
      {
        filename: "positions.csv",
        data: notWorkingOrders,
      },
      {
        filename: "accounts.csv",
        data: notWorkingOrders,
      },

      { filename: "all_orders.csv", data: allOrders },
    ];
  }

  timer = () => {
    clearInterval(this.intervalId);
    this.refreshData();
    this.intervalId = setInterval(this.timer, 5000);
  };

  componentDidMount = () => {
    this.refreshData();
    this.intervalId = setInterval(this.timer, 5000);
    //const socket = io("http://127.0.0.1:3333");
    //socket.on("dexmoocat:orders", data => {
    //  console.log("socket orders");
    //  this.processOrdersDataImage(data);
    //});
    //socket.emit("orders", "message from browser");
  };
  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  handleTabChange = (key) => {
    this.tab = key;
  };

  handleTableChange = (pagination, filters, sorter) => {};

  handleButtonClick = (e) => {
    this.exportTabToDownload();
  };

  exportTabToDownload() {
    const data = parse(this.tabs[this.tab].data);
    const blob = new Blob([data], { type: "application/text" });
    const url = window.URL.createObjectURL(blob);
    // console.log(url);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";

    a.href = url;
    a.download = this.tabs[this.tab].filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  actions = (
    <Button type="primary" icon="download" onClick={this.handleButtonClick}>
      Export Table Data
    </Button>
  );

  render() {
    return (
      <Tabs
        onChange={this.handleTabChange}
        type="card"
        className="tablesContainer"
        tabBarExtraContent={this.actions}
      >
        <TabPane tab="Orders" key="0">
          <WorkingOrdersTable
            className="workingOrdersTable"
            dataSource={this.state.workingOrders}
            size="small"
            pagination="true"
            position="both"
            rowKey="PKey"
            accountFilters={this.myAccountsFilterList}
          />
        </TabPane>

        <TabPane tab="Fills" key="1">
          <FilledOrdersTable
            className="filledOrdersTable"
            dataSource={this.state.fillOrders}
            size="small"
            pagination="true"
            position="both"
            rowKey="ClOrdID"
          />
        </TabPane>

        <TabPane tab="Rejected" key="2">
          <RejectedOrdersTable
            className="notWorkingOrdersTable"
            dataSource={this.state.notWorkingOrders}
            size="small"
            pagination="true"
            position="both"
            rowKey="ClOrdID"
          />
        </TabPane>

        <TabPane tab="Completed" key="3">
          <CompletedOrdersTable
            className="allOrdersTable"
            dataSource={this.state.allOrders}
            size="small"
            pagination="true"
            position="both"
            rowKey="ClOrdID"
          />
        </TabPane>

        <TabPane tab="Positions" key="4"></TabPane>
        <TabPane tab="Accounts" key="5"></TabPane>

        <TabPane tab="All" key="6">
          <WorkingOrdersTable
            className="workingOrdersTable"
            dataSource={this.state.allOrders}
            size="small"
            pagination="true"
            position="both"
            rowKey="PKey"
            accountFilters={this.myAccountsFilterList}
          />
        </TabPane>
      </Tabs>
    );
  }
}

export default App;
