import { authenticate as ccgAuthenticate } from "./api";

const emailKey = "thisIsCriminal";
const passwordKey = "evenWorse";
//const jwtKey = "jwt4tccg";

const storage = window.localStorage;

let singleton = null;

export const passwordRules = [
  {
    bitKey: 1,
    description: "At least 1 lowercase letter",
    regex: "(?=.*[a-z])",
    useRule: true
  },
  {
    bitKey: 2,
    description: "At least 1 uppercase letter",
    regex: "(?=.*[A-Z])",
    useRule: true
  },
  {
    bitKey: 4,
    description: "At least 1 number",
    regex: "(?=.*\\d)",
    useRule: true
  },
  {
    bitKey: 8,
    description: "At least 1 special character",
    regex: "(?=.*[-+_!@#$%^&*.,?])",
    useRule: true
  },
  {
    bitKey: 16,
    description: "Length between 7 and 12 characters",
    regex: "^.*{7,12}$",
    useRule: true
  }
];

export const statuses = {
  Authenticated: "AUTHENTICATED",
  Rejected: "REJECTED",
  None: "NONE"
};

class auth {
  constructor() {
    if (!singleton) {
      singleton = this;
    }
    // console.log(`auth cstr status ${this.authenticationStatus}`);
    return singleton;
  }

  authenticationStatus = null;

  getEmailAddress() {
    let email = storage.getItem(emailKey);
    if (!email || email === "") {
      throw new Error("Not Authenticated");
    }
    return email;
  }
  getPassword() {
    return storage.getItem(passwordKey);
  }

  getAuthenticationStatus() {
    if (!singleton.authenticationStatus) {
      //let jwt = storage.getItem(jwtKey);
      let email = storage.getItem(emailKey);
      let pwd = storage.getItem(passwordKey);
      let boolReturn = email && email !== "" && pwd;
      singleton.authenticationStatus = boolReturn
        ? statuses.Authenticated
        : statuses.None;
    }
    return singleton.authenticationStatus;
  }

  authenticate(email, password) {
    storage.setItem(emailKey, String(email));
    storage.setItem(passwordKey, String(password));
    
    return new Promise(function(resolve, reject) {
      ccgAuthenticate(email, password, storage)
        .then(response => {
          // console.log("auth resolved");
          
          //storage.setItem(jwtKey, JSON.stringify(response.data));
          singleton.authenticationStatus = statuses.Authenticated;

          resolve();
        })
        .catch(() => {
          console.log("auth rejected");

          //storage.removeItem(jwtKey);
          storage.removeItem(emailKey);
          storage.removeItem(passwordKey);
          singleton.authenticationStatus = statuses.Rejected;
          reject();
        });
    });
  }

  logout(cb) {
    //storage.removeItem(jwtKey);
    storage.removeItem(emailKey);
    storage.removeItem(passwordKey);
    singleton.authenticationStatus = statuses.None;
    if (cb && cb());
  }
}

export default new auth();
