export const tableColumns = [
  {
    key: "Account",
    dataIndex: "Account",
    title: "Account",
    order: "80",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "AccountA",
    dataIndex: "AccountA",
    title: "A/C",
    order: "90",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "AvgPx",
    dataIndex: "AvgPx",
    title: "AvgPx",
    order: "100",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "ClOrdID",
    dataIndex: "ClOrdID",
    title: "ClOrdID",
    order: "10",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "FillQty",
    dataIndex: "FillQty",
    title: "FillQty",
    order: "70",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "LimitPx",
    dataIndex: "LimitPx",
    title: "LmtPx",
    order: "60",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "OrdStatus",
    dataIndex: "OrdStatus",
    title: "Status",
    order: "20",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "OrdTIF",
    dataIndex: "OrdTIF",
    title: "TIF",
    order: "110",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "Qty",
    dataIndex: "Qty",
    title: "Qty",
    order: "50",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "Side",
    dataIndex: "Side",
    title: "B/S",
    order: "40",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "Symbol",
    dataIndex: "Symbol",
    title: "Symbol",
    order: "30",
    longName: "Y",
    tooltip: ""
  },
  {
    key: "User",
    dataIndex: "User",
    title: "User",
    order: "120",
    longName: "Y",
    tooltip: ""
  }

  //

  //
  //
  //
  //
];
