import moment from "moment";

export const orderTypes = [
  {
    key: "LMT",
    desc: "Limit",
    exchangeCode: "LIMIT",
    requiresPrice: true,
    requiresStopPrice: false,
    default: true
  },
  {
    key: "MKT",
    desc: "Market",
    exchangeCode: "MARKET",
    requiresPrice: false,
    requiresStopPrice: false
  },
  {
    key: "STP",
    desc: "Stop",
    exchangeCode: "STOPMARKET",
    requiresPrice: true,
    requiresStopPrice: false
  },
  {
    key: "STL",
    desc: "Stop Limit",
    exchangeCode: "STOPLIMIT",
    requiresPrice: true,
    requiresStopPrice: true
  }
];

export const tifTypes = [
  { key: "GTC", desc: "Til Cancel", requiresDate: false, requiresTime: false },
  {
    key: "DAY",
    desc: "For Day",
    requiresDate: false,
    requiresTime: false,
    default: true
  },
  { key: "GTD", desc: "Til Date", requiresDate: true, requiresTime: false },
  { key: "GTT", desc: "Til Time", requiresDate: false, requiresTime: true }
];

export const buySell = {
  Buy: {
    Single: "B",
    Code: "BUY"
  },
  Sell: {
    Single: "S",
    Code: "SELL"
  }
};

export function createBlankOrderTicket() {
  let defaultTif = tifTypes.find(tif => tif.default === true).key;
  let defaultOrderType = orderTypes.find(ot => ot.default === true).key;

  return {
    orderPrice: "",
    buySell: "",
    tif: defaultTif,
    tifDate: moment(),
    tifTime: moment(),
    orderType: defaultOrderType,
    requiresPrice: true,
    requiresTime: false,
    requiresDate: false,
    orderTicketValid: false,
    quantity: "",
    contract: ""
  };
}
