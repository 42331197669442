export const tableColumns = [
  {
    key: "Account",
    dataIndex: "Account",
    title: "Account",
    order: "120",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "AccountA",
    dataIndex: "AccountA",
    title: "A/C",
    order: "130",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "AvgPx",
    dataIndex: "AvgPx",
    title: "AvgPx",
    order: "115",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "ClOrdID",
    dataIndex: "ClOrdID",
    title: "ClOrdID",
    order: "15",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "FillQty",
    dataIndex: "FillQty",
    title: "FillQty",
    order: "50",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "LastFillQty",
    dataIndex: "LastFillQty",
    title: "LstFillQty",
    order: "80",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "LimitPx",
    dataIndex: "LimitPx",
    title: "LmtPx",
    order: "70",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "OpenQty",
    dataIndex: "OpenQty",
    title: "OpnQty",
    order: "60",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "OrdStatus",
    dataIndex: "OrdStatus",
    title: "Status",
    order: "10",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "OrdTIF",
    dataIndex: "OrdTIF",
    title: "TIF",
    order: "110",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "OrdType",
    dataIndex: "OrdType",
    title: "OrdTyp",
    order: "100",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "Qty",
    dataIndex: "Qty",
    title: "Qty",
    order: "40",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "ReferenceExchID",
    dataIndex: "ReferenceExchID",
    title: "RefExchID",
    order: "140",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "Side",
    dataIndex: "Side",
    title: "B/S",
    order: "30",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "StopPx",
    dataIndex: "StopPx",
    title: "StopPx",
    order: "90",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "SubmitTime",
    dataIndex: "SubmitTime",
    title: "SubTme",
    order: "150",
    longName: "",
    tooltip: "Y"
  },
  {
    key: "Symbol",
    dataIndex: "Symbol",
    title: "Symbol",
    order: "20",
    longName: "",
    tooltip: "Y"
  }

  //

  //
  //
  //
  //
];
