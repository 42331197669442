import React, { Component } from "react";
import { Table } from "antd";

import { getMarkets as apiGetMarkets, getPrices as apiGetPrices } from "./api";


function calcDirField(newNumber, oldNumber, prevDir) {
  //let timestamp = moment().format("x");

  if (newNumber === oldNumber) return prevDir;

  return Number.parseFloat(newNumber) > Number.parseFloat(oldNumber)
    ? "UP"
    : "DOWN";
}

function calcTickTock(newNumber, oldNumber, prevTickTock) {
  if (newNumber === oldNumber) return prevTickTock;

  return prevTickTock === "tick" ? "tock" : "tick";
}

function transformPrices(pricesJson, oldPrices) {
  function splitPriceQuantity(priceQuantityPair) {
    let quantity = priceQuantityPair ? priceQuantityPair.split("@")[0] : "";
    let price = priceQuantityPair ? priceQuantityPair.split("@")[1] : "";

    return { price, quantity };
  }

  var dataSource = [];
  for (var symbol in pricesJson) {
    let item = pricesJson[symbol];
    let maxDepth = Math.max(item.B.length, item.S.length);
    maxDepth = Math.min(maxDepth, 5); //limit order depth

    let result = splitPriceQuantity(item.B[0]);
    let BPx = result.price;
    let BQty = result.quantity;

    result = splitPriceQuantity(item.S[0]);
    let SPx = result.price;
    let SQty = result.quantity;
    /*
    "DUMMY-WIDGET-XXX": {
      B: ["3@95.43", "7@95.42", "12@95.41", "2@95.40"],
      S: ["10@95.46", "18@95.47"],
    */

    let touchPrice = {
      PKey: `${symbol}`,
      displayContract: symbol, //Only included in parent row
      contract: symbol,
      BQty,
      BPx,

      SPx,
      SQty,
      TTV: item.TTV,

      children: maxDepth > 1 ? [] : null
    };

    // eslint-disable-next-line
    let symbol_prev = oldPrices.find(entry => entry.PKey === symbol);
    if (symbol_prev) {
      /*
      let BQty_prev = symbol_prev.BQty;
      let SQty_prev = symbol_prev.SQty;
      */
      let BPx_prev = symbol_prev.BPx;
      let SPx_prev = symbol_prev.SPx;
      let TTV_prev = symbol_prev.TTV;

      //let BQty_dir = calcDirField(BQty, BQty_prev, symbol_prev.BQty_dir);
      let BPx_dir = calcDirField(BPx, BPx_prev, symbol_prev.BPx_dir);
      //let SQty_dir = calcDirField(SQty, SQty_prev, symbol_prev.SQty_dir);
      let SPx_dir = calcDirField(SPx, SPx_prev, symbol_prev.SPx_dir);
      let TTV_dir = calcDirField(item.TTV, TTV_prev, symbol_prev.TTV_dir);
      /*
      let BQty_ticktock = calcTickTock(
        BQty,
        BQty_prev,
        symbol_prev.BQty_ticktock
      );
      let SQty_ticktock = calcTickTock(
        SQty,
        SQty_prev,
        symbol_prev.SQty_ticktock
      );
*/
      let BPx_ticktock = calcTickTock(BPx, BPx_prev, symbol_prev.BPx_ticktock);

      let SPx_ticktock = calcTickTock(SPx, SPx_prev, symbol_prev.SPx_ticktock);
      let TTV_ticktock = calcTickTock(
        item.TTV,
        TTV_prev,
        symbol_prev.TTV_ticktock
      );

      touchPrice = {
        ...touchPrice,

        // BQty_prev,
        BPx_prev,
        SPx_prev,
        // SQty_prev,
        TTV_prev,

        // BQty_dir,
        BPx_dir,
        // SQty_dir,
        SPx_dir,
        TTV_dir,

        //BQty_ticktock,
        BPx_ticktock,
        //SQty_ticktock,
        SPx_ticktock,
        TTV_ticktock
      };
    }

    for (let orderDepthLoop = 1; orderDepthLoop < maxDepth; orderDepthLoop++) {
      let result = splitPriceQuantity(item.B[orderDepthLoop]);
      let BPx = result.price;
      let BQty = result.quantity;

      result = splitPriceQuantity(item.S[orderDepthLoop]);
      let SPx = result.price;
      let SQty = result.quantity;

      const childRow = {
        PKey: `${symbol}-${orderDepthLoop}`,
        contract: symbol,
        BQty,
        BPx,
        SPx,
        SQty
      };
      touchPrice.children.push(childRow);
    }
    dataSource.push(touchPrice);
  }
  let returnJSON = JSON.parse(JSON.stringify(dataSource));
  //// console.log(returnJSON);

  return returnJSON;
}

class PriceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allPrices: []
    };
    this.clickHandler = this.clickHandler.bind(this);
  }
  clickHandler(event, priceTableData) {
    this.props.onMarketClick(priceTableData);
  }

  directionToClass(dir, tickTock) {
    if (dir === "UP") return `priceTickUp_${tickTock}`;
    if (dir === "DOWN") return `priceTickDown_${tickTock}`;
    return "";
  }

  refreshData() {
    apiGetMarkets().then(res => {
      this.allMarkets = res.data.join(",");

      apiGetPrices(res.data).then(res => {
        let allPrices = transformPrices(res.data, this.state.allPrices);

        this.setState({ allPrices });
      });
    });
  }

  timer() {
    clearInterval(this.intervalId);
    this.refreshData();
    this.intervalId = setInterval(this.timer.bind(this), 5000);
  }
  componentDidMount() {
    this.refreshData();
    this.intervalId = setInterval(this.timer.bind(this), 5000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  render() {
    const pricesColumns = [
      {
        title: "Contract",
        dataIndex: "displayContract",
        key: "contract"
      },
      {
        title: "Buy Qty",
        dataIndex: "BQty",
        key: "BQty",
        render: (text, record) => (
          <span
            className={this.directionToClass(
              record.BQty_dir,
              record.BQty_ticktock
            )}
          >
            {text}
          </span>
        ),
        onCell: (record, index) => ({
          onClick: e => {
            this.clickHandler(e, {
              priceTableRecord: record,
              priceTableCell: "BQty"
            });
          }
        })
      },
      {
        title: "Buy Px",
        dataIndex: "BPx",
        key: "BPx",
        render: (text, record) => (
          <span
            className={this.directionToClass(
              record.BPx_dir,
              record.BPx_ticktock
            )}
          >
            {text}
          </span>
        ),
        onCell: (record, index) => ({
          onClick: e => {
            this.clickHandler(e, {
              priceTableRecord: record,
              priceTableCell: "BPx"
            });
          }
        })
      },

      {
        title: "Sell Px",
        dataIndex: "SPx",
        key: "SPx",
        render: (text, record) => (
          <span
            className={this.directionToClass(
              record.SPx_dir,
              record.SPx_ticktock
            )}
          >
            {text}
          </span>
        ),
        onCell: (record, index) => ({
          onClick: e => {
            this.clickHandler(e, {
              priceTableRecord: record,
              priceTableCell: "SPx"
            });
          }
        })
      },
      {
        title: "Sell Qty",
        dataIndex: "SQty",
        key: "SQty",
        render: (text, record) => (
          <span
            className={this.directionToClass(
              record.SQty_dir,
              record.SQty_ticktock
            )}
          >
            {text}
          </span>
        ),
        onCell: (record, index) => ({
          onClick: e => {
            this.clickHandler(e, {
              priceTableRecord: record,
              priceTableCell: "SQty"
            });
          }
        })
      },
      {
        title: "Volume",
        dataIndex: "TTV",
        key: "TTV",
        render: (text, record) => (
          <span
            className={this.directionToClass(
              record.TTV_dir,
              record.TTV_ticktock
            )}
          >
            {text}
          </span>
        )
      }
    ];
    let pagination = {
      defaultPageSize: 100,
      disabled: "true",
      hideOnSinglePage: "true",
      position: "none"
    };
    return (
      <div className="pricetable">
        <Table
          dataSource={this.state.allPrices}
          columns={pricesColumns}
          bordered
          rowKey="PKey"
          size="small"
          pagination={pagination}
        />
      </div>
    );
  }
}

export default PriceTable;
