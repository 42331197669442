import React, { Component } from "react";

import { Table } from "antd";
import { tableColumns as filledOrdersColumns } from "./FilledOrdersGeneratedColumns";

function decorateColumns(cols, colSelector, colDecorations) {
  let col = cols.find(col => col.key === colSelector);
  //debugger;

  if (col) {
    let index = cols.indexOf(col);
    col = {
      ...col,
      defaultSortOrder: "descend",

      ...colDecorations
    };
    cols[index] = col;
  }
  //debugger;
}

class FilledOrdersTable extends Component {
  constructor(props) {
    super(props);

    let cols = filledOrdersColumns.sort((a, b) => a.order - b.order);

    decorateColumns(cols, "ClOrdID", {
      sorter: (a, b) => String(a.ClOrdID).localeCompare(b.ClOrdID)
    });

    decorateColumns(cols, "SystemText", {
      sorter: (a, b) => String(a.SystemText).localeCompare(b.SystemText)
    });

    decorateColumns(cols, "MarketName", {
      sorter: (a, b) => String(a.MarketName).localeCompare(b.MarketName)
    });

    decorateColumns(cols, "OrdStatus", {
      sorter: (a, b) => String(a.OrdStatus).localeCompare(b.OrdStatus),
      fixed: "left",
      filters: [
        { text: "Part Filled", value: "PARTIALLY_FILLED" },
        { text: "New", value: "NEW" }
      ],
      //    filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.OrdStatus === value
    });

    decorateColumns(cols, "Side", {
      sorter: (a, b) => String(a.Side).localeCompare(b.Side),

      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "LimitPx", {
      sorter: (a, b) => String(a.LimitPx).localeCompare(b.LimitPx),
      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "StopPx", {
      sorter: (a, b) => String(a.StopPx).localeCompare(b.StopPx),

      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "Qty", {
      sorter: (a, b) => String(a.Qty).localeCompare(b.Qty),
      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "OpenQty", {
      sorter: (a, b) => String(a.OpenQty).localeCompare(b.OpenQty)
    });

    decorateColumns(cols, "FillQty", {
      sorter: (a, b) => String(a.FillQty).localeCompare(b.FillQty)
    });

    decorateColumns(cols, "LastFillQty", {
      sorter: (a, b) => String(a.LastFillQty).localeCompare(b.LastFillQty)
    });

    decorateColumns(cols, "OrdType", {
      sorter: (a, b) => String(a.OrdType).localeCompare(b.OrdType)
    });

    decorateColumns(cols, "OrdTIF", {
      sorter: (a, b) => String(a.OrdTIF).localeCompare(b.OrdTIF)
    });

    decorateColumns(cols, "Account", {
      sorter: (a, b) => String(a.Account).localeCompare(b.Account),
      onFilter: (value, record) => record.Account === value,
      filters: this.props.accountFilters
    });

    decorateColumns(cols, "SubmitTime", {
      sorter: (a, b) => String(a.SubmitTime).localeCompare(b.SubmitTime)
    });

    decorateColumns(cols, "TransactionTime", {
      sorter: (a, b) =>
        String(a.TransactionTime).localeCompare(b.TransactionTime)
    });

    decorateColumns(cols, "EffectiveTime", {
      sorter: (a, b) => String(a.EffectiveTime).localeCompare(b.EffectiveTime)
    });

    decorateColumns(cols, "ExpireTime", {
      sorter: (a, b) => String(a.ExpireTime).localeCompare(b.ExpireTime)
    });

    decorateColumns(cols, "ExchID", {
      sorter: (a, b) => String(a.ExchID).localeCompare(b.ExchID)
    });

    decorateColumns(cols, "ReferenceClOrdID", {
      sorter: (a, b) =>
        String(a.ReferenceClOrdID).localeCompare(b.ReferenceClOrdID)
    });

    this.FilledOrdersColumns = cols;
  }
  FilledOrdersColumns = [];

  renderSideFields = function(text, record) {
    return record.Side === "BUY" ? (
      <span className="buyField">{text}</span>
    ) : (
      <span className="sellField">{text}</span>
    );
  };

  render() {
    return (
      <Table
        scroll={{ x: true }}
        className="FilledOrdersTable"
        dataSource={this.props.dataSource}
        columns={this.FilledOrdersColumns}
        size="small"
        pagination="true"
        position="both"
        rowKey="ClOrdID"
      />
    );
  }
}

export default FilledOrdersTable;
