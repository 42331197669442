import React, { Component } from "react";
import { Select } from "antd";
import { contracts } from "../Development/mockData";

class ContractSelect extends Component {
  onContractChange = (value, option) => {
    this.props.onChange(value, option);
  };

  render() {
    const { Option } = Select;
    const contractOptions = contracts.map(contract => (
      <Option key={contract.key}>{contract.key}</Option>
    ));
    return (
      <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Select Contract"
        optionFilterProp="children"
        value={this.props.contract}
        onChange={this.onContractChange}
        //onFocus={onFocus}
        //onBlur={onBlur}
        //onSearch={onSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {contractOptions}
      </Select>
    );
  }
}
export default ContractSelect;
