export const workingTableColumns = [
  {
    key: "actions",
    dataIndex: "actions",
    title: "actions",
    order: "20",
    longName: "",
    tooltip: ""
  },
  {
    key: "Account",
    dataIndex: "Account",
    title: "Account",
    order: "150",
    longName: "Account",
    tooltip: "Account is filled in when execution unsolocited---POSS ISSUE---"
  },
  {
    key: "ClOrdID",
    dataIndex: "ClOrdID",
    title: "ClOrdID",
    order: "10",
    longName: "ClientOrderID",
    tooltip: ""
  },
  {
    key: "ExchID",
    dataIndex: "ExchID",
    title: "ExchID",
    order: "140",
    longName: "ExchangeID",
    tooltip: ""
  },
  {
    key: "ExpireTime",
    dataIndex: "ExpireTime",
    title: "Expiry",
    order: "130",
    longName: "Expiry",
    tooltip: ""
  },
  {
    key: "FillQty",
    dataIndex: "FillQty",
    title: "FillQty",
    order: "110",
    longName: "Fill Qty",
    tooltip: ""
  },
  {
    key: "LastFillQty",
    dataIndex: "LastFillQty",
    title: "LstFillQty",
    order: "115",
    longName: "LastFillQty",
    tooltip: ""
  },
  {
    key: "LimitPx",
    dataIndex: "LimitPx",
    title: "LmtPx",
    order: "100",
    longName: "Limit Price",
    tooltip: ""
  },
  {
    key: "OpenQty",
    dataIndex: "OpenQty",
    title: "OpnQty",
    order: "70",
    longName: "OpenQty",
    tooltip: ""
  },
  {
    key: "OrdStatus",
    dataIndex: "OrdStatus",
    title: "Status",
    order: "30",
    longName: "Status",
    tooltip: ""
  },
  {
    key: "OrdTIF",
    dataIndex: "OrdTIF",
    title: "TIF",
    order: "120",
    longName: "TimeInForce",
    tooltip: ""
  },
  {
    key: "OrdType",
    dataIndex: "OrdType",
    title: "OrdTyp",
    order: "110",
    longName: "OrderType",
    tooltip: ""
  },
  {
    key: "Qty",
    dataIndex: "Qty",
    title: "Qty",
    order: "60",
    longName: "Qty",
    tooltip: ""
  },
  {
    key: "Side",
    dataIndex: "Side",
    title: "B/S",
    order: "50",
    longName: "Buy/Sell",
    tooltip: ""
  },
  {
    key: "StopPx",
    dataIndex: "StopPx",
    title: "StopPx",
    order: "80",
    longName: "StopPx",
    tooltip: ""
  },
  {
    key: "SubmitTime",
    dataIndex: "SubmitTime",
    title: "SubTme",
    order: "150",
    longName: "SubmitTime",
    tooltip: ""
  },
  {
    key: "Symbol",
    dataIndex: "Symbol",
    title: "Symbol",
    order: "40",
    longName: "Symbol",
    tooltip: ""
  },
  {
    key: "User",
    dataIndex: "User",
    title: "User",
    order: "160",
    longName: "User",
    tooltip: ""
  }
];

//export const workingExportColumns = [];
