import React, { Component } from "react";
import { Select } from "antd";
import { orderTypes } from "../consts";

const { Option, OptGroup } = Select;

class OrderTypeSelect extends Component {
  render() {
    const onChange = newValue => {
      this.props.onChange(newValue);
    };

    return (
      <Select
        value={this.props.value}
        style={{ width: "100%" }}
        onChange={onChange}
      >
        <OptGroup label="Order Type">
          {orderTypes.map(ot => {
            return <Option key={ot.key}>{ot.key}</Option>;
          })}
        </OptGroup>
      </Select>
    );
  }
}

export default OrderTypeSelect;
