export const tradingAccounts = [
  { key: "DEXMOOCAT-YYY1-IU1SYKU17", name: "DEXMOOCAT-YYY1-IU1SYKU17" },
  { key: "DEXMOOCAT-XXX1-2U1SYKU16", name: "DEXMOOCAT-XXX1-2U1SYKU16" },
  { key: "DEXMOOCAT-GADGET1-XU1SYKU19", name: "DEXMOOCAT-GADGET1-XU1SYKU19" }
];

export const contracts = [
  { key: "DUMMY-XXX-WIDGET" },
  { key: "DUMMY-XXX-YYY" },
  { key: "DUMMY-WIDGET-GADGET" },
  { key: "DUMMY-BOBBIN-XXX" },
  { key: "DUMMY-WIDGET10-XXX" },
  { key: "DUMMY-WIDGET10S-XXX" },
  { key: "CERT-USD-TEST" },
  { key: "BLOCK-USD-TEST-NOSCL" },
  { key: "BLOCK-USD-TEST-SCL--" }
];
