import React, { Component } from "react";
import { Select } from "antd";
import { tifTypes } from "../consts";
const { Option, OptGroup } = Select;

class OrderTIFSelect extends Component {
  onChange = newValue => {
    this.props.onChange(newValue);
  };

  render() {
    return (
      <Select
        value={this.props.value}
        style={{ width: "100%" }}
        onChange={this.onChange}
      >
        <OptGroup label="Time In Force">
          {tifTypes.map(tif => {
            return <Option key={tif.key}>{tif.key}</Option>;
          })}
        </OptGroup>
      </Select>
    );
  }
}

export default OrderTIFSelect;
