import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Trading from "./Trading";
import Login from "./Login";

import "react-flags-select/css/react-flags-select.css";

import "./index.css";
import "./pulsing.css";

import * as serviceWorker from "./serviceWorker";

import auth, { statuses as authenticationStatuses } from "./auth";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        auth.getAuthenticationStatus() ===
        authenticationStatuses.Authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

class App extends Component {
  state = { authenticationStatus: auth.getAuthenticationStatus() };

  handleLogInOut = () => {
    let authenticationStatus = auth.getAuthenticationStatus();
    // console.log(
    //  `handleLogInOut ${authenticationStatus} ${this.state.authenticationStatus}`
    //);
    if (authenticationStatus !== this.state.authenticationStatus) {
      this.setState({ authenticationStatus });
    }
  };

  render() {
    return (
      <Router>
        <PrivateRoute
          path={["/", "/night", "/day"]}
          exact
          component={Trading}
          authenticationStatus={this.state.authenticationStatus}
          onLogInOut={this.handleLogInOut}
        />

        <Route
          path="/login"
          //component={Login}
          render={props => (
            <Login
              {...props}
              authenticationStatus={this.state.authenticationStatus}
              onLogInOut={this.handleLogInOut}
            />
          )}
        />
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
