import React, { Component } from "react";
import { message } from "antd";
import moment from "moment";
import { cancelOrder as apiCancelOrder } from "../api";
import { Popconfirm } from "antd";
import { Button, Table } from "antd";
import { workingTableColumns } from "./WorkingOrdersGeneratedColumns";

function decorateColumns(cols, colSelector, colDecorations) {
  let col = cols.find(col => col.key === colSelector);
  //debugger;

  if (col) {
    let index = cols.indexOf(col);
    col = {
      ...col,
      defaultSortOrder: "descend",

      ...colDecorations
    };
    cols[index] = col;
  }
  //debugger;
}

class WorkingOrdersTable extends Component {
  constructor(props) {
    super(props);

    let cols = workingTableColumns.sort((a, b) => a.order - b.order);

    decorateColumns(cols, "ClOrdID", {
      sorter: (a, b) => String(a.ClOrdID).localeCompare(b.ClOrdID),
      fixed: "left"
    });

    decorateColumns(cols, "actions", {
      fixed: "left",
      render: (text, record) => (
        <Popconfirm
          title="Please confirm that this order should be cancelled"
          cancelText="Forget it"
          okText="Cancel Order"
          onConfirm={() => this.cancelOrder(record)}
        >
          <Button type="danger" icon="close-circle">
            Cancel
          </Button>
        </Popconfirm>
      )
    });

    decorateColumns(cols, "SystemText", {
      sorter: (a, b) => String(a.SystemText).localeCompare(b.SystemText)
    });

    decorateColumns(cols, "MarketName", {
      sorter: (a, b) => String(a.MarketName).localeCompare(b.MarketName)
    });

    decorateColumns(cols, "OrdStatus", {
      sorter: (a, b) => String(a.OrdStatus).localeCompare(b.OrdStatus),

      filters: [
        { text: "Part Filled", value: "PARTIALLY_FILLED" },
        { text: "New", value: "NEW" }
      ],
      //    filteredValue: filteredInfo.name || null,
      onFilter: (value, record) => record.OrdStatus === value
    });

    decorateColumns(cols, "Side", {
      sorter: (a, b) => String(a.Side).localeCompare(b.Side),

      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "LimitPx", {
      sorter: (a, b) => String(a.LimitPx).localeCompare(b.LimitPx),
      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "StopPx", {
      sorter: (a, b) => String(a.StopPx).localeCompare(b.StopPx),

      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "Qty", {
      sorter: (a, b) => String(a.Qty).localeCompare(b.Qty),
      render: (text, record) => this.renderSideFields(text, record)
    });

    decorateColumns(cols, "OpenQty", {
      sorter: (a, b) => String(a.OpenQty).localeCompare(b.OpenQty)
    });

    decorateColumns(cols, "FillQty", {
      sorter: (a, b) => String(a.FillQty).localeCompare(b.FillQty)
    });

    decorateColumns(cols, "LastFillQty", {
      sorter: (a, b) => String(a.LastFillQty).localeCompare(b.LastFillQty)
    });

    decorateColumns(cols, "OrdType", {
      sorter: (a, b) => String(a.OrdType).localeCompare(b.OrdType)
    });

    decorateColumns(cols, "OrdTIF", {
      sorter: (a, b) => String(a.OrdTIF).localeCompare(b.OrdTIF)
    });

    decorateColumns(cols, "Account", {
      sorter: (a, b) => String(a.Account).localeCompare(b.Account),
      onFilter: (value, record) => record.Account === value,
      filters: this.props.accountFilters
    });

    decorateColumns(cols, "SubmitTime", {
      sorter: (a, b) => String(a.SubmitTime).localeCompare(b.SubmitTime)
    });

    decorateColumns(cols, "TransactionTime", {
      sorter: (a, b) =>
        String(a.TransactionTime).localeCompare(b.TransactionTime)
    });

    decorateColumns(cols, "EffectiveTime", {
      sorter: (a, b) => String(a.EffectiveTime).localeCompare(b.EffectiveTime)
    });

    decorateColumns(cols, "ExpireTime", {
      sorter: (a, b) => String(a.ExpireTime).localeCompare(b.ExpireTime)
    });

    decorateColumns(cols, "ExchID", {
      sorter: (a, b) => String(a.ExchID).localeCompare(b.ExchID)
    });

    decorateColumns(cols, "ReferenceClOrdID", {
      sorter: (a, b) =>
        String(a.ReferenceClOrdID).localeCompare(b.ReferenceClOrdID)
    });

    this.workingOrdersColumns = cols;
  }
  workingOrdersColumns = [];

  renderSideFields = function(text, record) {
    return record.Side === "BUY" ? (
      <span className="buyField">{text}</span>
    ) : (
      <span className="sellField">{text}</span>
    );
  };

  cancelOrder = function(record) {
    const orderCancelMessage = {
      ReferenceExchID: record.ExchID,
      ClOrdID: moment().format("x"), //record.ClOrdID,
      Symbol: record.Symbol,
      OrdType: record.OrdType,
      OrdMessage: "F",
      ReferenceClOrdID: record.ClOrdID
    };

    apiCancelOrder(orderCancelMessage)
      .then(res => {
        for (var field of res.data) {
          switch (field["39"]) {
            case "REJECTED": {
              message.warning(field["39"]);
              // console.log(res.data);
              break;
            }
            case "PENDING_NEW": {
              message.success(field["39"]);
              break;
            }
            case "PENDING_CANCEL": {
              message.success(
                "Successfully created cancellation order : " + field["11"]
              );
              break;
            }
            default: {
              // console.log(res);
              message.error("Systems Problem - Retry");
            }
          }
        }
      })
      .catch(res => {
        // console.log(res);
      });
  };

  render() {
    return (
      <Table
        scroll={{ x: true }}
        className="workingOrdersTable"
        dataSource={this.props.dataSource}
        columns={this.workingOrdersColumns}
        size="small"
        pagination="true"
        position="both"
        rowKey="ClOrdID"
      />
    );
  }
}

export default WorkingOrdersTable;
