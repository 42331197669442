import axios from "axios";
import auth from "./auth";

const apiBaseUrl = process.env.REACT_APP_CCG_API_BASE; 

export function authenticate(email, password) {
  const url = `${apiBaseUrl}/api/v2/auth`;
  const body = { email, password };

  const headers = {
    "content-type": "application/json",
    "Cache-Control": "no-cache",
  };

  let apiPromise = new Promise(function (resolve, reject) {
    axios
      .post(url, body, createSuiHeaders() )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
           console.log(error.response.data);
           console.log(error.response.status);
           console.log(error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);

        //Check the http status code

        reject();
      });
  });
  return apiPromise;
}

function createSuiHeaders() {
  let suiHeaders = {
    headers: {
      "content-type": "application/json",
      "Cache-Control": "no-cache",
    },
    auth: {
      username: auth.getEmailAddress(),
      password: auth.getPassword(),
    },
  };
  return suiHeaders;
}

function getSpecific(key, specifier) {
  return getAny(`${key}/${specifier}`);
}

function getAny(key) {
  var url = `${apiBaseUrl}/api/v1/${key}`;

  let promise = new Promise(function (resolve, reject) {
    axios
      .get(url, createSuiHeaders())
      .then((res) => resolve(res))
      .catch((res) => reject(res));
  });
  return promise;
}
export function getAccounts() {
  return getAny("Account");
}

export function getAccount(account) {
  return getSpecific("Account", account);
}

export function getOrders() {
  return getAny("Order");
}

export function getFills() {
  return getAny("Fill");
}

export function getPrices(marketsArray) {
  let marketsCSV = marketsArray.join(",");
  return getSpecific("Price", marketsCSV);
}

export function getMarkets() {
  return getAny("Market");
}

export function createOrder(orderMessage) {
  var url = `${apiBaseUrl}/api/v1/Order`;

  let promise = new Promise(function (resolve, reject) {
    axios
      .put(
        url,
        [orderMessage],

        createSuiHeaders()
      )
      .then((res) => resolve(res))
      .catch((res) => reject(res));
  });
  return promise;
}

export function cancelOrder(orderMessage) {
  //Same function, message type created defines the intention
  return createOrder(orderMessage);
}
