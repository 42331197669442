import React, { Component } from "react";
import { Row, Col } from "antd";
import { Button } from "antd";
import { message } from "antd";
import auth from "../auth";
import { createOrder as apiCreateOrder } from "../api";

import OrderTypeSelect from "./OrderTypeSelect";
import OrderTIFSelect from "./OrderTIFSelect";
import NumericInput from "./NumericInput";
import ContractSelect from "./ContractSelect";
import { TimePicker } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import {
  orderTypes,
  tifTypes,
  buySell as bs,
  createBlankOrderTicket
} from "../consts";

//import "./App.css";

class OrderTicket extends Component {
  state = createBlankOrderTicket();

  onOrderPriceChange = orderPrice => {
    this.setState({ orderPrice });
  };

  onTifTimeChange = tifTime => {
    this.setState({ tifTime });
  };

  onContractChange = (value, option) => {
    let blankOrderTicket = createBlankOrderTicket();

    this.setState({ ...blankOrderTicket, contract: option.key });
    this.props.onContractChange();
  };

  onTifDateChange = tifDate => {
    this.setState({ tifDate });
  };

  onOrderQuantityChange = quantity => {
    this.setState({ quantity });
  };

  onOrderTifChange = tifType => {
    let tif = tifTypes.find(item => item.key === tifType);

    let requiresTime = tif.requiresTime;
    let requiresDate = tif.requiresDate;

    this.setState({ requiresTime });
    this.setState({ requiresDate });
    this.setState({ tif: tifType });
  };

  onOrderTypeChange = orderType => {
    let requiresPrice = orderTypes.find(item => {
      return item.key === orderType;
    }).requiresPrice;

    // console.log(orderType);
    this.setState({ orderType });
    this.setState({ requiresPrice });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    //if we haven't been passed a price record, then we have nothing to do

    if (!nextProps.priceTableRecord) return null;

    if (
      !prevState.priceTableRecord ||
      prevState.priceTableRecord.contract !==
        nextProps.priceTableRecord.contract ||
      prevState.priceTableCell !== nextProps.priceTableCell
    ) {
      const buySell =
        String(nextProps.priceTableCell).substring(0, 1) === bs.Buy.Single
          ? bs.Sell.Single
          : bs.Buy.Single;

      const orderPrice =
        buySell === bs.Buy.Single
          ? nextProps.priceTableRecord.SPx
          : nextProps.priceTableRecord.BPx;

      const quantity =
        buySell === bs.Buy.Single
          ? nextProps.priceTableRecord.SQty
          : nextProps.priceTableRecord.BQty;

      let ot = createBlankOrderTicket();
      return {
        ...ot,
        buySell,
        orderPrice,
        quantity,
        contract: nextProps.priceTableRecord.contract,
        priceTableRecord: nextProps.priceTableRecord,
        priceTableCell: nextProps.priceTableCell
      };
    } else {
      if (nextProps.priceTableRecord.contract !== prevState.contract) {
        return { contract: nextProps.priceTableRecord.contract };
      }
    }
  }

  onSellClick = () => {
    if (this.state.buySell !== bs.Sell.Single)
      this.setState({ buySell: bs.Sell.Single });
  };
  onBuyClick = () => {
    if (this.state.buySell !== bs.Buy.Single)
      this.setState({ buySell: bs.Buy.Single });
  };

  handleSubmitClick = () => {
    const ClOrdID = moment();
    const buySell =
      this.state.buySell === bs.Buy.Single ? bs.Buy.Code : bs.Sell.Code;
    const exOrderType = orderTypes.find(ot => ot.key === this.state.orderType)
      .exchangeCode;

    let OrdTIF = this.state.tif;
    let ExpireTime = "";
    if (this.state.tif === "GTD") {
      ExpireTime = this.state.tifDate._d;
    }
    if (this.state.tif === "GTT") {
      ExpireTime = this.state.tifTime._d;
      OrdTIF = "GTD";
    }
    const orderTicketMessage = {
      ClOrdID: ClOrdID.format("x"), //int ms since epoch
      Symbol: this.state.contract,
      Side: buySell,
      User: auth.getEmailAddress,
      OrdType: exOrderType,
      Qty: this.state.quantity,
      OrdTIF,
      //LimitPx: this.state.orderPrice,
      Account: this.props.account,
      ExpireTime,
      FreeText: "Web2 Order",
      OrdMessage: "D"
    };

    switch (this.state.orderType) {
      case "LMT": {
        orderTicketMessage.LimitPx = this.state.orderPrice;
        break;
      }
      case "STL": {
        orderTicketMessage.LimitPx = this.state.orderPrice;
        orderTicketMessage.StopPx = this.state.orderPrice; //New field required
        break;
      }
      case "STP": {
        //orderTicketMessage.LimitPx = this.state.orderPrice;
        orderTicketMessage.StopPx = this.state.orderPrice;
        break;
      }
      case "MKT": {
        //Nop
        break;
      }
      default: {
        // console.log("Unexpected order type");
      }
    }

    if (!this.state.contract || this.state.contract === "") {
      message.error("Contract not selected");
      return;
    }

    if (!this.state.quantity || this.state.quantity === "") {
      message.error("Quantity not provided");
      return;
    }

    if (!this.props.account || this.props.account === "") {
      message.error("Account not selected");
      return;
    }
    // console.log(orderTicketMessage);

    apiCreateOrder(orderTicketMessage)
      .then(res => {
        for (var field of res.data) {
          switch (field["39"]) {
            case "REJECTED": {
              message.warning("Rejected : order " + field["11"]);
              // console.log(res.data);
              break;
            }
            case "PENDING_NEW": {
              // console.log(field);
              message.success("Successfully created order " + field[11]);
              break;
            }
            default: {
              message.error("Systems Problem - Retry");
            }
          }
        }
      })
      .catch(res => {
        // console.log(res);
      });
  };
  typeIsBuy = () => {
    return this.state.buySell === bs.Buy.Single;
  };

  typeIsSell = () => {
    return this.state.buySell === bs.Sell.Single;
  };

  submitString = () => {
    let submitString = "";

    if (this.state.buySell !== "") {
      submitString =
        this.state.buySell === bs.Buy.Single ? bs.Buy.Code : bs.Sell.Code;
      submitString += ` ${this.state.quantity} @`;
      submitString += ` ${
        this.state.requiresPrice ? this.state.orderPrice : ""
      }`;
      submitString += ` ${this.state.orderType} `;
      submitString += ` ${this.state.tif}`;
      if (this.state.requiresDate)
        submitString += ` ${this.state.tifDate.format("Do MMM YY")}`;
      if (this.state.requiresTime)
        submitString += ` ${this.state.tifTime.format("LT")}`;
    }

    return submitString;
  };

  render() {
    return (
      <div className="orderticket ant-table-small">
        <Row>
          <Col span={5}>Contract</Col>
          <Col span={19}>
            <ContractSelect
              contract={this.state.contract}
              onChange={this.onContractChange}
            ></ContractSelect>
          </Col>
        </Row>

        <Row>
          <Col span={5}></Col>
          <Col span={8}>
            <Button
              type="primary"
              block
              onClick={this.onBuyClick}
              {...{ ghost: !this.typeIsBuy() }}
            >
              BUY
            </Button>
          </Col>
          <Col span={8}>
            <Button
              type="danger"
              block
              onClick={this.onSellClick}
              {...{ ghost: !this.typeIsSell() }}
            >
              SELL
            </Button>
          </Col>
          <Col span={3}></Col>
        </Row>

        <Row>
          <Col span={5}>Quantity</Col>
          <Col span={8}>
            <NumericInput
              className="quantityInput"
              placeholder="Quantity"
              min={0}
              max={100000}
              value={this.state.quantity}
              onChange={this.onOrderQuantityChange}
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={11}></Col>
        </Row>
        <Row>
          <Col span={5}>Price</Col>
          <Col span={8}>
            <NumericInput
              className="priceInput"
              placeholder="Price"
              value={this.state.requiresPrice ? this.state.orderPrice : null}
              onChange={this.onOrderPriceChange}
              disabled={!this.state.requiresPrice}
              //style={{ display: this.state.requiresPrice ? "block" : "none" }}
            />
          </Col>
          <Col span={8}>
            <OrderTypeSelect
              value={this.state.orderType}
              onChange={this.onOrderTypeChange}
            ></OrderTypeSelect>
          </Col>

          <Col span={3}></Col>
        </Row>
        <Row>
          <Col span={5}>TIF</Col>
          <Col span={8}>
            <OrderTIFSelect
              value={this.state.tif}
              onChange={this.onOrderTifChange}
            ></OrderTIFSelect>
          </Col>
          <Col span={8}>
            <TimePicker
              value={this.state.tifTime}
              onChange={this.onTifTimeChange}
              style={{ display: this.state.requiresTime ? "block" : "none" }}
              format="HH:mm"
            />
            <DatePicker
              value={this.state.tifDate}
              onChange={this.onTifDateChange}
              style={{ display: this.state.requiresDate ? "block" : "none" }}
              format="DD MMM YY"
            />
          </Col>
          <Col span={3}></Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              onClick={this.handleSubmitClick}
              type={
                this.state.buySell === bs.Sell.Single ? "danger" : "primary"
              }
              block
              {...{ ghost: !this.state.orderTicketValid }}
            >
              {this.submitString()}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default OrderTicket;
