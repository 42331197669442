import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Input, AutoComplete } from "antd";

import { Button } from "antd";
import "./login.css";
import auth, {
  passwordRules,
  statuses as authenticationStatuses
} from "./auth";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      email: "",
      password: "",
      redirectToReferrer:
        auth.getAuthenticationStatus() === authenticationStatuses.Authenticated
    };
  }

  handleEmailChange = value => {
    /*
    this.setState({
      dataSource:
        !value || value.indexOf("@") >= 0
          ? []
          : [
              `${value}@gmail.com`,
              `${value}@hotmail.com`,
              `${value}@cryptocommoditygroup.com`
            ]
    });
    */
    this.setState({ email: value });
  };

  handlePasswordFieldChange = value => {
    this.setState({ password: value.target.value });
  };

  handleSubmit = value => {
    //Authenticate user name & password

    auth
      .authenticate(this.state.email, this.state.password)
      .then(() => {
        // console.log("Callback received");

        this.setState({ redirectToReferrer: true });
        this.props.onLogInOut();
      })
      .catch(() => {
        //Didn't work out - give feedback
        this.props.onLogInOut();
      });
  };

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    let { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      // console.log(`redirecting from ${from}`);
      return <Redirect to={from} />;
    }

    return (
      <div className="logincontainercontainercontainer clearme">
        <div className="logincontainercontainer">
          <div className="logincontainer">
            <div className="logintitle">Log In</div>
            <div className="welcome">Welcome to your CCG trading system </div>
            <div className="clearme">
              <AutoComplete
                className="email"
                defaultValue={this.email}
                dataSource={this.state.dataSource}
                onChange={this.handleEmailChange}
                placeholder="Your email ID"
              />
              <div className="horibreak" />
              <div className="clearme">
                <Input.Password
                  className="password"
                  placeholder="********"
                  onChange={this.handlePasswordFieldChange}
                />
              </div>
              <div className="horibreak" />
              <div className="clearme">
                <Button
                  className="loginButton"
                  onClick={this.handleSubmit}
                  block
                  size="large"
                >
                  Login
                </Button>
              </div>
              {this.props.authenticationStatus ===
                authenticationStatuses.Rejected && (
                <div>You trying to blag your way in?</div>
              )}
              <div className="smallprint clearme">
                Reminder of our password rules
              </div>
              {passwordRules.map(item => (
                <div key={item.bitKey} className="smallprint">
                  {item.description}
                </div>
              ))}
            </div>
            <div className="clearme" />
          </div>
        </div>
      </div>
    );
    //
  }
}

export default Login;
